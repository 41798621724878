<template>
    <mdb-container fluid>
        <Header></Header>
        <Admintopbar></Admintopbar>
        <mdb-card class="mt-3">
            <mdb-card-header class="yellow darken-3 white-text">
                <mdb-spinner small color="white" v-if="test === null" />
                <h4 small color="white" v-if="test !== null">
                    Attempt for test "{{test.name}}" by {{email}}
                </h4>
            </mdb-card-header>
            <mdb-card-body>
                <div class="d-flex w-100">
                    <mdb-spinner small color="blue" v-if="completedtests === null" />
                    <div v-if="completedtests !== null" class="w-100">
                        <mdb-row class="w-100">
                            <mdb-col>
                                <mdb-btn color="secondary" v-on:click="$router.push('/admin/tests')">
                                    <mdb-icon icon="arrow-left" />
                                </mdb-btn>
                            </mdb-col>
                            <mdb-col>
                                <h4>Submitted at {{addedathourmin}}</h4>
                                <mdb-btn color="success" :disabled="approved === '1'" v-on:click="approve(testAttemptId)" >
                                    ACCEPT
                                </mdb-btn>
                                <mdb-btn  color="danger" :disabled="approved === '0'" v-on:click="reject(testAttemptId)" >
                                    REFUSE
                                </mdb-btn>
                            </mdb-col>
                        </mdb-row>
                        <div v-if="!optional">
                            <div v-for="(completedtest, index) in completedtests" class="w-100"
                                :key="index"
                            >
                                <h4 style="display: inline-block;">Question #{{index+1}}</h4><span v-if="completedtest.correctly_answered == 1" style="background-color: lightgreen; padding: 5px; margin: 5px;">Correctly answered</span>
                                <div :inner-html.prop="completedtest.html"></div>
                                <h5>Answer:</h5>
                                <div>
                                    <mdb-input v-if="completedtest.type === 'SHORTTEXT'" :value="completedtest.answer" readOnly />
                                    <mdb-input type="textarea" v-if="completedtest.type === 'LONGTEXT'" :value="completedtest.answer" readOnly />
                                    <div v-if="completedtest.type === 'ABCD'">
                                        <ol class="visualizer" style="list-style: upper-alpha">
                                            <li class="text-wrap" v-for="(line, indexline) in placeholderToValues(completedtest.placeholder)" :key="indexline">
                                                {{line}} <span v-if="completedtest.answer === line /*|| completedtest.answer === String.fromCharCode(indexline+'A'.charCodeAt(0))*/"><mdb-icon icon="check" /></span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div v-if="completedtest.type === 'MULTIPLE-ANSWER'">
                                        <ul class="visualizer">
                                            <li class="text-wrap" v-for="(line, indexline) in placeholderToValues(completedtest.placeholder)" :key="indexline">
                                                {{line}} <span v-if="completedtest.answer.split(',').includes(line) /*|| completedtest.answer.split(',').map((element, index) => (index+'A'.charCodeAt(0))).includes(indexline+'A'.charCodeAt(0))*/"><mdb-icon icon="check" /></span>
                                            </li>
                                          </ul>
                                    </div>
                                </div>
                                <div v-if="completedtest.correct_answer">
                                  <h5>Correct answer:</h5>
                                  <mdb-input v-if="completedtest.type !== 'LONGTEXT'" v-model="completedtest.correct_answer" readOnly/>
                                  <mdb-input v-else v-model="completedtest.correct_answer" type="textarea" readOnly />
                                </div>
                                <hr />
                            </div>
                        </div>
                    <div v-else>
                        <div v-for="(completedtest, index) in arrayWithoutSpecialQuestions" class="w-100"
                                :key="index"
                            >
                            <h4 style="display: inline-block;">Question #{{index+1}}</h4><span v-if="completedtest.correctly_answered == 1" style="background-color: lightgreen; padding: 5px; margin: 5px;">Correctly answered</span>
                                <div :inner-html.prop="completedtest.html"></div>
                                <h5>Answer:</h5>
                                <div>
                                    <mdb-input v-if="completedtest.type === 'SHORTTEXT'" :value="completedtest.answer" readOnly />
                                    <mdb-input type="textarea" v-if="completedtest.type === 'LONGTEXT'" :value="completedtest.answer" readOnly />
                                    <div v-if="completedtest.type === 'ABCD'">
                                        <ol class="visualizer" style="list-style: upper-alpha">
                                            <li class="text-wrap" v-for="(line, indexline) in placeholderToValues(completedtest.placeholder)" :key="indexline">
                                                {{line}} <span v-if="completedtest.answer === line /*|| completedtest.answer === String.fromCharCode(indexline+'A'.charCodeAt(0))*/"><mdb-icon icon="check" /></span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div v-if="completedtest.type === 'MULTIPLE-ANSWER'">
                                        <ul class="visualizer">
                                            <li class="text-wrap" v-for="(line, indexline) in placeholderToValues(completedtest.placeholder)" :key="indexline">
                                                {{line}} <span v-if="completedtest.answer.split(',').includes(line) /*|| completedtest.answer.split(',').map((element, index) => (index+'A'.charCodeAt(0))).includes(indexline+'A'.charCodeAt(0))*/"><mdb-icon icon="check" /></span>
                                            </li>
                                          </ul>
                                    </div>
                                </div>
                                <div v-if="completedtest.correct_answer">
                                  <h5>Correct answer:</h5>
                                  <mdb-input v-if="completedtest.type !== 'LONGTEXT'" v-model="completedtest.correct_answer" readOnly/>
                                  <mdb-input v-else v-model="completedtest.correct_answer" type="textarea" readOnly />
                                </div>
                                <hr />
                            </div>
                            <mdb-btn color="primary" v-on:click="showOther = !showOther" >
                                {{ showOther ? 'Hide the automatically reviewed questions' : 'See the automatically reviewed questions'}}
                            </mdb-btn>
                        </div>
                        <div v-if="showOther">
                            <div v-for="completedOptional, index in optionalArray" class="w-100"
                                :key="index"
                            >
                            <h4 style="display: inline-block;">Question #{{index+1}}</h4><span v-if="completedOptional.correctly_answered == 1" style="background-color: lightgreen; padding: 5px; margin: 5px;">Correctly answered</span>
                                <div :inner-html.prop="completedOptional.html"></div>
                                <h5>Answer:</h5>
                                <div>
                                    <div v-if="completedOptional.type === 'ABCD'">
                                        <ol class="visualizer" style="list-style: upper-alpha">
                                            <li class="text-wrap" v-for="(line, indexline) in placeholderToValues(completedOptional.placeholder)" :key="indexline">
                                                {{line}} <span v-if="completedOptional.answer === line /*|| completedOptional.answer === String.fromCharCode(indexline+'A'.charCodeAt(0))*/"><mdb-icon icon="check" /></span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div v-if="completedOptional.type === 'MULTIPLE-ANSWER'">
                                        <ul class="visualizer">
                                            <li class="text-wrap" v-for="(line, indexline) in placeholderToValues(completedOptional.placeholder)" :key="indexline">
                                                {{line}} <span v-if="completedOptional.answer.split(',').includes(line) /*|| completedOptional.answer.split(',').map((element, index) => (index+'A'.charCodeAt(0))).includes(indexline+'A'.charCodeAt(0))*/"><mdb-icon icon="check" /></span>
                                            </li>
                                          </ul>
                                    </div>
                                </div>
                                <div v-if="completedOptional.correct_answer">
                                  <h5>Correct answer:</h5>
                                  <mdb-input v-if="completedOptional.type !== 'LONGTEXT'" v-model="completedOptional.correct_answer" readOnly/>
                                  <mdb-input v-else v-model="completedOptional.correct_answer" type="textarea" readOnly />
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </mdb-container>
</template>

<script>
import Admintopbar from '@/components/Admintopbar.vue';
import Header from '@/components/Header.vue';
import {
  mdbBtn,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbCol,
  mdbRow,
  mdbContainer,
  mdbIcon,
  mdbInput,
  mdbSpinner,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';

export default {
    components: {
        Admintopbar,
        Header,
        mdbBtn,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbCol,
        mdbContainer,
        mdbIcon,
        mdbInput,
        mdbRow,
        mdbSpinner,
    },

    data() {
        return {
            testid: null,
            email: null,
            addedathourmin: null,
            completedtests: null,
            test: null,
            testAttemptId: null,
            approved: null,
            optional: false,
            optionalArray: null,
            arrayWithoutSpecialQuestions: null,
            showOther: false,
        };
    },

    mounted() {
        this.testid = this.$route.query.testid;
        this.email = this.$route.query.email;
        this.addedathourmin = this.$route.query.addedathourmin;

        this.getCompletedTest();
    },

    methods: {
        getCompletedTest() {
            const api = new Apicall();
            api.call('GET', `api/admintest/tests?id=${this.testid}`).then((response) => {
                this.test = response.data.testinfos;
            });

            api.call('POST', 'api/admintest/get-answers', {
              testid: this.testid,
              email: this.email,
              addedathourmin: this.addedathourmin,
            }).then((response) => {
                this.completedtests = response.data.completedtestinfos;
                this.testAttemptId = response.data.testAttemptId;
                this.approved = response.data.approved;
                this.optional = response.data.optional;
                this.arrayWithoutSpecialQuestions = response.data.arrayWithoutSpecialQuestions;
                this.optionalArray = response.data.optionalArray;
            });
        },

        placeholderToValues(value) {
            return value.split(/\r?\n/);
        },
        approve(_testAttemptId) {
            const api = new Apicall();
            api.call('POST', 'api/testattempts/review', {
                testAttemptId: _testAttemptId, validate: 'true',
            }).then((response) => {
                this.getCompletedTest();
            });
        },
        reject(_testAttemptId) {
            const api = new Apicall();
            api.call('POST', 'api/testattempts/review', {
                testAttemptId: _testAttemptId, validate: 'false',
            }).then((response) => {
                this.getCompletedTest();
            });
        },
    },

    filters: {
    },
};
</script>

<style>

</style>
